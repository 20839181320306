<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Dashboard</h1>
            </div>
            <div class="row">
              <section class="welcome__card sixteen wide column">
                <div class="ui card">
                  <div class="content">
                    <h1>Olá, {{ admin_info.firstName }}</h1>
                    <div class="content">
                      <h4 class="ui sub header">Seja bem-vindo(a).</h4>
                      <p>
                        Este é o painel adminstrativo das Assinaturas da Thomson
                        Reuters
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="row">
              <section class="welcome__card sixteen wide column">
                <h2>Pesquisar assinaturas</h2>
                <div class="ui card">
                  <div class="content">
                    <div class="ui action input">
                      <select
                        class="ui compact selection dropdown"
                        v-model="searchOption"
                      >
                        <option selected value>Escolher</option>
                        <option value="status">Status</option>
                        <option value="email">Email</option>
                        <option value="originalOrderId"
                          >Número do pedido</option
                        >
                      </select>
                      <input
                        type="text"
                        placeholder="Pesquisar ..."
                        v-model="searchTerm"
                        :disabled="searchOption.length === 0"
                        @keyup="
                          ({ key }) => key == 'Enter' && searchOnEnterPressed()
                        "
                      />
                      <button
                        class="ui button positive"
                        @click="() => configSearchFilter() | getSubscriptions()"
                        :disabled="
                          searchTerm.length < 3 || searchOption.length === 0
                        "
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="row">
              <section class="subscription__section sixteen wide column">
                <div class="ui two column padded grid">
                  <div class="row">
                    <div class="two wide column subscription__section-title">
                      <h2>Assinaturas</h2>
                    </div>
                    <div class="one wide column">
                      <button
                        class="circular ui left floated icon mini button"
                        @click="updateSubscriptionList"
                        title="Atualizar"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="subscription__section-table-wrapper">
                  <div class="ui active inverted dimmer" v-if="loading">
                    <div class="ui loader"></div>
                  </div>
                  <table class="ui selectable celled table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Próxima cobrança</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="subscription in subscriptions"
                        :key="subscription.id"
                        class="user__list"
                      >
                        <td class="selectable">
                          <router-link
                            class="user__list-link"
                            :to="
                              '/backoffice/subscription/id/' +
                                subscription.id +
                                '/groupId/' +
                                subscription.orderGroup
                            "
                            >{{ subscription.id }}</router-link
                          >
                        </td>
                        <td class="selectable">
                          <router-link
                            class="user__list-link"
                            :to="
                              '/backoffice/subscription/id/' +
                                subscription.id +
                                '/groupId/' +
                                subscription.orderGroup
                            "
                            >{{ subscription.customerId }}</router-link
                          >
                        </td>
                        <td class="selectable">
                          <router-link
                            class="user__list-link"
                            :to="
                              '/backoffice/subscription/id/' +
                                subscription.id +
                                '/groupId/' +
                                subscription.orderGroup
                            "
                          >
                            {{
                              $moment(subscription.nextPurchaseDate)
                                .tz($moment.tz.guess())
                                .format("DD MMM YYYY")
                            }}
                          </router-link>
                        </td>
                        <td class="selectable">
                          <router-link
                            class="user__list-link d-flex ai-center jc-spacen-between"
                            :to="
                              '/backoffice/subscription/id/' +
                                subscription.id +
                                '/groupId/' +
                                subscription.orderGroup
                            "
                          >
                            {{ subscription.status }}
                            <span
                              :class="
                                'bullet__status pulse bullet__status--' +
                                  subscription.status.toLowerCase()
                              "
                            ></span>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                    <tbody class="no-data" v-if="!subscriptions.length">
                      <tr>
                        <td colspan="3">
                          <h2>Nenhuma assinatura encontrada</h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div class="row">
              <Pagination
                :page="search.pagination.page"
                :pages="pageCount"
                :clicked="paginate"
              ></Pagination>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../Template/Backoffice";
import Pagination from "../../../components/Pagination";

export default {
  name: "Dashboard",
  components: {
    Backoffice,
    Pagination
  },
  computed: {
    admin_info() {
      let adminInfo = {
        email: "",
        id: "",
        image_path: null,
        firstName: "",
        lastName: ""
      };

      if (this.$store.getters["User/getAdminInfo"]) {
        adminInfo = this.$store.getters["User/getAdminInfo"];
      }

      return adminInfo;
    }
  },
  data() {
    return {
      loading: false,
      sidebar: {
        type: "Admin",
        data: null
      },
      pageCount: 1,
      searchOption: "",
      searchTerm: "",
      search: {
        filter: {},
        pagination: {
          page: 1,
          paginate: 5
        }
      },
      subscriptions: []
    };
  },
  methods: {
    updateSubscriptionList() {
      let { $store } = this;

      this.searchOption = "";
      this.searchTerm = "";

      this.search.filter = {};
      $store.commit("Dashboard/setSearchConfig", {
        search: this.search,
        searchTerm: this.searchTerm
      });

      this.getSubscriptions();
    },
    configSearchFilter(pageNum = 1) {
      const { search, searchTerm, searchOption, $store } = this;

      search.filter = {};

      if (searchTerm.length >= 3 && searchOption.length > 0) {
        search.pagination.page = pageNum;
        search.filter[searchOption] = searchTerm;
        $store.commit("Dashboard/setSearchConfig", { search, searchTerm });
      }
    },
    searchOnEnterPressed() {
      if (!this.searchTerm.length >= 3 || this.searchOption.length > 0) {
        this.configSearchFilter();
        this.getSubscriptions();
      }
    },
    getSubscriptions(search = null) {
      this.loading = true;

      this.$store
        .dispatch("Subscriptions/GET_SUBSCRIPTIONS", search || this.search)
        .then(_response => {
          this.pageCount = _response.pages;
          this.subscriptions = _response.list;
          this.loading = false;
        });
    },
    paginate(pageNum = 1) {
      this.configSearchFilter(pageNum);
      console.log(pageNum);
      const {
        searchConfig: { search }
      } = this.$store.state.Dashboard;

      search.pagination.page = pageNum;
      this.search.pagination.page = pageNum;

      this.getSubscriptions(search);
    }
  },
  beforeCreate() {},
  created() {
    const {
      searchConfig: { search, searchTerm }
    } = this.$store.state.Dashboard;

    this.configSearchFilter();

    this.searchTerm = searchTerm;

    const pageNum = search.pagination.page;
    this.search.pagination.page = pageNum;

    this.getSubscriptions(search);
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.welcome__card {
  .card {
    width: 100%;
  }
}
.subscription__section {
  width: 100%;
  &-table-wrapper {
    position: relative;
    width: 100%;
  }
}

.subscription__section-title {
  padding-left: 0px !important;
}

.user__list {
  a {
    display: block;
    width: 100%;
    @media screen and (max-width: 768px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 768px) {
  .subscription__section-table-wrapper {
    .table {
      display: block;
    }
  }
}
</style>
